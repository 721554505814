import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; 
import './ConsultationForm.css';
import { db } from '../firebase'; 
import { collection, addDoc } from "firebase/firestore"; 
import Modal from './ConsultationModel'; 
import axios from 'axios';

const ConsultationForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState(''); // Added phone number state
    const [timeSlot, setTimeSlot] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with today's date
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const [loading, setLoading] = useState(false); // Track loading state

    const timeSlots = ["9AM-10AM", "11AM-12PM", "3PM-4PM", "4PM-5PM", "7PM-8PM", "9PM-10PM"];
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Validate form data
        if (!name || !email || !phone || !timeSlot || !selectedDate) {
            alert("Please fill all the details.");
            return; // Stop form submission if validation fails
        }

        setLoading(true); // Set loading state to true when form submission begins

        try {
            const docRef = await addDoc(collection(db, "consultations"), {
                name,
                email,
                phone, // Include phone in Firestore data
                timeSlot,
                selectedDate,
            });

            await axios.post('https://rowx-backend-dot-project-101-396902.el.r.appspot.com/api/send-email', {
                name,
                email,
                phone, // Include phone in email payload
                timeSlot,
                selectedDate,
            });

            setSubmittedData({ name, email, phone, timeSlot, selectedDate });
            setIsModalOpen(true);
        } catch (e) {
            console.error("Error adding document: ", e);
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="consultation-form-container">
            <div className="form-section">
                <h2>Book a Consultation Session</h2>
                <form className="consultation-form" onSubmit={handleFormSubmit}>
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className={name ? 'input-filled' : ''}
                        placeholder="Enter your name"
                        disabled={loading} // Disable input while loading
                    />

                    <label htmlFor="email">Business Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={email ? 'input-filled' : ''}
                        placeholder="Enter your email"
                        disabled={loading} 
                    />

                    <label htmlFor="phone">Phone Number</label>
                    <input
                        type="tel"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className={phone ? 'input-filled' : ''}
                        placeholder="Enter your phone number"
                        disabled={loading} // Disable input while loading
                    />

                    <label>Choose a Time Slot</label>
                    <div className="time-slots">
                        {timeSlots.map(slot => (
                            <button
                                type="button"
                                key={slot}
                                className={`time-slot ${timeSlot === slot ? 'selected' : ''}`}
                                onClick={() => setTimeSlot(slot)}
                                disabled={loading} // Disable time slots while loading
                            >
                                {slot}
                            </button>
                        ))}
                    </div>

                    <label htmlFor="date">Select a Date</label>
                    <div className="calendar-container">
                        <Calendar
                            onChange={handleDateChange}
                            value={selectedDate}
                            minDate={new Date()} // Disable past dates
                        />
                    </div>

                    <div className="floating-footer">
                        <div className="price-info">
                            <div className="original-price">Rs 999</div>
                            <div className="discounted-price">Rs 0</div>
                        </div>
                        <button
                            type="submit"
                            className="submit-btn"
                            disabled={loading} // Disable submit button while loading
                        >
                            {loading ? 'Submitting...' : 'Book Now'}
                        </button>
                    </div>
                </form>
            </div>

            <Modal isOpen={isModalOpen} onClose={handleCloseModal} data={submittedData} />
        </div>
    );
};

export default ConsultationForm;
